import React from 'react';
import styled from 'styled-components/macro';

import Cube from './cube.svg';
import Clock from './clock.svg';
import IDCard from './id-card.svg';
import Person from './person.svg';
import Target from './target.svg';
import FileText from './file-text.svg';
import Archive from './archive.svg';
import ListBullet from './list-bullet.svg';
import Slider from './slider.svg';
import Gear from './gear.svg';
import QuestionMarkCircled from './question-mark-circled.svg';
import QuestionMark from './question-mark.svg';
import MagnifyingGlass from './magnifying-glass.svg';
import HamburgerMenu from './hamburger-menu.svg';
import BarChart from './bar-chart.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import ChevronDown from './chevron-down.svg';
import ChevronUp from './chevron-up.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import TriangleLeft from './triangle-left.svg';
import TriangleUp from './triangle-up.svg';
import TriangleDown from './triangle-down.svg';
import CaretSort from './caret-sort.svg';
import CaretSortFlipped from './caret-sort-flipped.svg';
import ChatBubble from './chat-bubble.svg';
import Check from './check.svg';
import CheckCircled from './check-circled.svg';
import CheckCircledFilled from './check-circled-filled.svg';
import Cross1 from './cross-1.svg';
import Cross2 from './cross-2.svg';
import CrossCircled from './cross-circled.svg';
import CrossCircledFilled from './cross-circled-filled.svg';
import Update from './update.svg';
import ExclamationTriangle from './exclamation-triangle.svg';
import ExclamationTriangleFilled from './exclamation-triangle-filled.svg';
import DragHandleVertical from './drag-handle-vertical.svg';
import DragHandleHorizontal from './drag-handle-horizontal.svg';
import DragHanldeDots2 from './drag-handle-dots-2.svg';
import Height from './height.svg';
import Width from './width.svg';
import Plus from './plus.svg';
import PlusCircled from './plus-circled.svg';
import PlusCircledFilled from './plus-circled-filled.svg';
import MinusCircledFilled from './minus-circled-filled.svg';
import Minus from './minus.svg';
import DotsVertical from './dots-vertical.svg';
import DotsHorizontal from './dots-horizontal.svg';
import CaretDown from './caret-down.svg';
import CaretUp from './caret-up.svg';
import Sun from './sun.svg';
import Moon from './moon.svg';
import Pencil from './pencil-1.svg';
import Pencil2 from './pencil-2.svg';
import Avatar from './avatar.svg';
import Trash from './trash.svg';
import CounterClockWiseClock from './counter-clockwise-clock.svg';
import Download from './download.svg';
import Filter from './filter.svg';
import Copy from './copy.svg';
import Calendar from './calendar.svg';
import TextAlignLeft from './text-align-left.svg';
import Bell from './bell.svg';
import Bell2 from './bell-2.svg';
import Reset from './reset.svg';
import Code from './code.svg';
import FilePlus from './file-plus.svg';
import Rocket from './rocket.svg';
import Backpack from './backpack.svg';
import ExternalLink from './external-link.svg';
import EnvelopeClosed from './envelope-closed.svg';
import EnvelopeOpen from './envelope-open.svg';
import LockClosed from './lock-closed.svg';
import LockOpen1 from './lock-open-1.svg';
import Reload from './reload.svg';
import Activity from './activity-log.svg';
import Globe from './globe.svg';
import Timer from './countdown-timer.svg';
import Holiday from './umbrella.svg';
import Dashboard from './dashboard.svg';
import Star from './star.svg';

const SVGS = {
  hours: <Clock />,
  cv: <IDCard />,
  profile: <Person />,
  projects: <Cube />,
  estimates: <Target />,
  offers: <FileText />,
  reports: <Archive />,
  list: <ListBullet />,
  timeline: <Slider />,
  settings: <Gear />,
  guide: <QuestionMarkCircled />,
  search: <MagnifyingGlass />,
  magnifyingGlass: <MagnifyingGlass />,
  burger: <HamburgerMenu />,
  hamburger: <HamburgerMenu />,
  menu: <HamburgerMenu />,
  chatBubble: <ChatBubble />,
  chevronLeft: <ChevronLeft />,
  chevronRight: <ChevronRight />,
  chevronDown: <ChevronDown />,
  chevronUp: <ChevronUp />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  triangleLeft: <TriangleLeft />,
  triangleUp: <TriangleUp />,
  triangleDown: <TriangleDown />,
  check: <Check />,
  checkCircled: <CheckCircled />,
  checkCircledFilled: <CheckCircledFilled />,
  cross1: <Cross1 />,
  cross2: <Cross2 />,
  crossCircled: <CrossCircled />,
  crossCircledFilled: <CrossCircledFilled />,
  update: <Update />,
  exclamationTriangle: <ExclamationTriangle />,
  exclamationTriangleFilled: <ExclamationTriangleFilled />,
  dragHandleVertical: <DragHandleVertical />,
  dragHandleHorizontal: <DragHandleHorizontal />,
  dragHanldeDots2: <DragHanldeDots2 />,
  height: <Height />,
  width: <Width />,
  plus: <Plus />,
  plusCircled: <PlusCircled />,
  plusCircledFilled: <PlusCircledFilled />,
  minusCircledFilled: <MinusCircledFilled />,
  minus: <Minus />,
  dotsVertical: <DotsVertical />,
  dotsHorizontal: <DotsHorizontal />,
  caretDown: <CaretDown />,
  caretUp: <CaretUp />,
  caretSort: <CaretSort />,
  caretSortFlipped: <CaretSortFlipped />,
  expand: <CaretSort />,
  shrink: <CaretSortFlipped />,
  sun: <Sun />,
  moon: <Moon />,
  edit: <Pencil />,
  pencil2: <Pencil2 />,
  avatar: <Avatar />,
  trash: <Trash />,
  counterClockWiseClock: <CounterClockWiseClock />,
  download: <Download />,
  copy: <Copy />,
  calendar: <Calendar />,
  textAlignLeft: <TextAlignLeft />,
  bell: <Bell />,
  bell2: <Bell2 />,
  filter: <Filter />,
  reset: <Reset />,
  code: <Code />,
  filePlus: <FilePlus />,
  rocket: <Rocket />,
  backpack: <Backpack />,
  question: <QuestionMark />,
  external: <ExternalLink />,
  email: <EnvelopeClosed />,
  emailOpen: <EnvelopeOpen />,
  lockClosed: <LockClosed />,
  lockOpen: <LockOpen1 />,
  reload: <Reload />,
  activity: <Activity />,
  globe: <Globe />,
  timer: <Timer />,
  holiday: <Holiday />,
  dashboard: <Dashboard />,
  star: <Star />,
  chart: <BarChart />
};

export const IconContainer = styled.div`
  width: ${props => props.size || '1rem'};
  height: ${props => props.size || '1rem'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
  path {
    fill: ${({ fill }) => fill || 'currentColor'};
  }

  animation: ${({ animated }) => animated && 'rotate 1.2s infinite linear'};

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Icon = props => {
  const { name, ...rest } = props;

  if (!SVGS[name]) {
    throw new Error(`no SVG for: ${name}`);
  }
  return <IconContainer {...rest}>{SVGS[name]}</IconContainer>;
};

export default Icon;
