import { types } from 'mobx-state-tree';
import moment from 'moment';

import { MomentType, LazyEmployee } from './types';
import { Employee } from './employee-model';

const Proposition = types
  .model('DealProposition', {
    id: types.union(types.number, types.string),
    role: types.maybeNull(types.string),
    employee: types.union(types.maybeNull(LazyEmployee), Employee),
    employee_text: types.maybeNull(types.string),
    comment: types.optional(types.string, ''),
    created_at: types.optional(MomentType, moment()),
    updated_at: types.optional(MomentType, moment()),
    interview: types.maybeNull(types.boolean, false),
    winner: types.maybeNull(types.boolean, false),
    _destroy: types.maybe(types.boolean)
  })
  .postProcessSnapshot(snapshot => {
    if (snapshot.id === -1 || Number.isNaN(Number(snapshot.id))) {
      delete snapshot.id; // eslint-disable-line no-param-reassign
    }
    return snapshot;
  })
  .actions(self => ({
    setComment(value) {
      self.comment = value; // eslint-disable-line no-param-reassign
    },
    setRole(value) {
      self.role = value; // eslint-disable-line no-param-reassign
    },
    setInterview(value) {
      self.interview = value; // eslint-disable-line no-param-reassign
    },
    setWinner(value) {
      self.winner = value; // eslint-disable-line no-param-reassign
    },
    markForDestroy() {
      self._destroy = true; // eslint-disable-line no-param-reassign
    }
  }));

export default Proposition;
