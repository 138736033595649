import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const EmployeeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EmployeeAction = styled.div`
  opacity: 0;
  transition: opacity 0.2s linear;
  @media (pointer: coarse) {
    opacity: 1;
  }
  ${EmployeeContainer}:hover & {
    opacity: 1;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const EmployeeList = styled.div`
  position: absolute;
  width: max-content;
  min-width: 100%;
  max-width: 150%;
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--color-grey-lightest);
  border-radius: var(--border-radius-xs);
  background-color: var(--color-modal);
  color: var(--color-text-dark);

  z-index: 160;
`;

export const EmployeeWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-input-hover);
  }

  &:focus {
    outline: none;
    border-color: var(--color-input-focus);
    border-radius: var(--border-radius-xs);
  }
`;
