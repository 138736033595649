import React, { useRef, useContext, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ActionMenu, Icon } from 'components/atoms';
import { UiContext } from 'contexts';
import {
  StyledPopup,
  MegaModalTopbar,
  MegaModalTitle,
  MegaModalClose,
  MegaModalContent
} from './mega-modal-style';

const MegaModal = observer(
  forwardRef(({ title, open, onClose, children, actionItems }, ref) => {
    const { setOpen, setModalType } = useContext(UiContext);
    const popupRef = ref || useRef();

    // Ensure this closes even when using things like browser history
    useEffect(() => {
      return () => setOpen(false);
    }, []);

    return (
      <StyledPopup
        closeOnEscape
        closeOnDocumentClick
        arrow={false}
        onOpen={() => {
          setModalType('mega');
          setOpen(true);
        }}
        onClose={() => {
          setModalType(null);
          setOpen(false);
          onClose();
        }}
        contentStyle={false}
        open={open}
        ref={popupRef}
      >
        <MegaModalTopbar>
          <MegaModalTitle>{title}</MegaModalTitle>
          {Array.isArray(actionItems) && actionItems.length > 0 && (
            <ActionMenu items={actionItems} portalBreakpoint />
          )}
          <MegaModalClose onClick={() => popupRef.current.close()}>
            <Icon name='cross1' size='var(--font-size-xl)' />
          </MegaModalClose>
        </MegaModalTopbar>
        <MegaModalContent>
          <>{children}</>
        </MegaModalContent>
      </StyledPopup>
    );
  })
);

export default MegaModal;

MegaModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionItems: PropTypes.array
};
