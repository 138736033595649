import React from 'react';
import PropTypes from 'prop-types';

import { Button, SimpleInput } from 'components/atoms';
import {
  TabContainer,
  TabLink,
  Tab,
  activeClassName
} from './tab-select-style';

const linkProps = tab => {
  const { to, onClick, color } = tab;
  const as = to ? undefined : 'div';
  const className = tab.active ? activeClassName : undefined;

  return { to, onClick, as, color, className };
};

const TabSelect = props => {
  const { tabs, additionalAction, onLabelChange, disabled, noEdit } = props;
  const labelEditable = onLabelChange && !noEdit;
  return (
    <TabContainer $static={props.static}>
      {tabs.map(item => (
        <TabLink
          exact
          key={item.label}
          {...linkProps(item)}
          disabled={disabled}
        >
          <Tab>
            <span style={{ opacity: item.active && labelEditable ? 0 : 1 }}>
              {item.label}
            </span>
            {item.active && labelEditable && (
              <SimpleInput
                defaultValue={item.label}
                onCommit={onLabelChange}
                onClick={e => e.stopPropagation()}
              />
            )}
          </Tab>
        </TabLink>
      ))}
      {additionalAction && tabs.length < 3 ? (
        <TabLink as='div'>
          <Button
            transparent
            icon={additionalAction.icon}
            small
            onClick={additionalAction.onClick}
            disabled={disabled}
          >
            {additionalAction.label}
          </Button>
        </TabLink>
      ) : null}
    </TabContainer>
  );
};

export default TabSelect;

TabSelect.propTypes = {
  tabs: PropTypes.array.isRequired
};
