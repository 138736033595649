import React from 'react';
import { observer } from 'mobx-react';

import { Icon } from 'components/atoms';

import { IncrementWrapper, Button } from './increment-buttons-style';

const IncrementButtons = observer(
  ({ children, increment, value, remove, opts = {} }) => {
    const { min = 1, max = Number.POSITIVE_INFINITY } = opts;

    const atMin = value === min;
    const atMax = value === max;

    const deleteNext = atMin && remove;

    const decr = () => {
      if (deleteNext) remove();
      else increment(-1);
    };

    const incr = () => {
      increment(1);
    };

    return (
      <IncrementWrapper>
        <Button onClick={decr} disabled={atMin && !remove}>
          {deleteNext ? (
            <Icon name='trash' fill='var(--color-error-dark)' />
          ) : (
            <Icon name='minus' />
          )}
        </Button>
        <div>{children}</div>
        <Button onClick={incr} disabled={atMax}>
          <Icon name='plus' />
        </Button>
      </IncrementWrapper>
    );
  }
);

export default IncrementButtons;
