import React from 'react';
import { observer, Observer } from 'mobx-react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { useSettings } from 'common/hooks';
import { Icon } from 'components/atoms';

import { Avatar } from '../employee-avatar/avatar';
import { AvatarContainer } from '../employee-avatar/avatar-style';

export const AvatarAndNameContainer = styled.div`
  position: relative;
  a,
  > div {
    display: flex;
    align-items: center;
    color: currentColor;
  }
  ${AvatarContainer} {
    margin-right: 0.5rem;
  }
  div {
    line-height: 1.2;
  }
  span {
    display: block;
    color: var(--color-text);
    font-size: var(--font-size-sm);
  }
  em {
    font-style: normal;
    background: var(--color-primary-lightest);
  }
`;

const ExtIcon = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: -1rem;
  color: var(--color-primary-lighter) !important;
`;

const ListAvatar = observer(props => {
  const { company_id } = useSettings();
  const { linkPath, avatar, name, id, title, style, noLink, small } = props;
  const path = linkPath || `/employees/${id}/cv`;
  const L = path && !noLink ? Link : 'div';

  return (
    <AvatarAndNameContainer style={style}>
      <L to={path} tabIndex={props.tabIndex}>
        <Avatar
          src={avatar}
          alt=''
          title={name}
          large={Boolean(title)}
          small={small}
          list
        />
        <div>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: name }} />
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </L>
      {/* TODO: This is a temp solution, make a unified solution with search results */}
      {props.company_id && props.company_id !== company_id && (
        <ExtIcon title='External company employee'>
          <Icon name='external' size='var(--font-size-sm)' />
        </ExtIcon>
      )}
    </AvatarAndNameContainer>
  );
});

// rendering employee as mapped data
export const ListAvatarRenderer = (_, data) => {
  return <ListAvatar {...data} />;
};

// rendering employee as a field
export const ListAvatarFieldRenderer = employee => {
  const data = { ...employee, name: employee.fullName() };
  return <Observer render={() => <ListAvatar {...data} />} />;
};

export default ListAvatar;
