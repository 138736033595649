import {
  types,
  flow,
  getSnapshot,
  destroy,
  applySnapshot,
  resolveIdentifier
} from 'mobx-state-tree';
import moment from 'moment';
import { withValidations } from 'mst-validatejs';

import Decimal, { MomentType, LazyEmployee } from './types';
import { Employee } from './employee-model';
import { Customer } from './customer-model';
import { Requirement } from './deal-requirement-model';
import DealProposition from './deal-proposition-model';
import axios from '../common/utils/axios';

export const Deal = types
  .model('Deal', {
    id: types.identifierNumber,
    name: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    value: types.optional(Decimal, 0),
    hourly_price: types.optional(Decimal, 0),
    start: types.maybeNull(MomentType),
    end: types.maybeNull(MomentType),
    current_step: types.maybeNull(types.number),
    max_step: types.maybeNull(types.number),
    status: types.maybeNull(
      types.enumeration(['active', 'won', 'lost']),
      'active'
    ),
    stage: types.maybeNull(types.string),
    stage_name: types.maybeNull(types.string),
    business_unit: types.maybeNull(types.string),
    sector: types.maybeNull(types.string),
    contact: types.union(types.maybeNull(LazyEmployee), Employee),
    propositions: types.array(DealProposition),
    requirements: types.array(Requirement),
    resourcing_deadline: types.maybeNull(MomentType),
    closed_at: types.maybeNull(MomentType),
    created_at: types.optional(MomentType, moment()),
    updated_at: types.optional(MomentType, moment()),
    collapsed: types.optional(types.boolean, true),
    customer: types.maybeNull(Customer),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      description: {
        length: { minimum: 1, message: 'description is required' }
      },
      start: {
        validDateRange: true
      },
      end: {
        validDateRange: true
      },
      expires: {
        validDateRange: true
      },
      contact: {
        presence: true
      },
      hourly_price: {
        numericality: true
      },
      value: {
        numericality: true
      }
    })
  )
  .postProcessSnapshot(snapshot => {
    // eslint-disable-next-line no-param-reassign
    snapshot.contact_id =
      typeof snapshot.contact === 'number'
        ? snapshot.contact
        : (snapshot.contact || { id: null }).id; // TODO: fix this mess
    return snapshot;
  })
  .views(self => ({
    isReady() {
      return self.state === 'ready';
    },
    allPropositions() {
      return [...self.propositions];
    },
    allRequirements() {
      return [...self.requirements];
    },
    validPropositions() {
      return self.allPropositions().filter(prop => !prop._destroy);
    },
    validRequirements() {
      return self
        .allRequirements()
        .filter(req => !req.isEmpty() && !req._destroy);
    },
    propositionById(id) {
      return resolveIdentifier(DealProposition, self, id);
    },
    propositionByEmployeeId(id) {
      const proposition = self.propositions.find(p => p.employee_id === id);
      if (proposition) {
        return proposition;
      }
      return null;
    },
    get resourcingPercentage() {
      const { req, filled } = self.validRequirements().reduce(
        (o, r) => ({
          req: o.req + r.amount,
          filled: o.filled + Math.min(r.amount, r.filled)
        }),
        { req: 0, filled: 0 }
      );

      return (filled * 100) / (req || 1);
    },
    get stageName() {
      return self.status === 'active' ? self.stage_name : self.status;
    },
    get isClosed() {
      return self.status !== 'active';
    }
  }))
  .actions(self => ({
    load: flow(function* load(id) {
      if ([-1, undefined].includes(id)) return; // TODO: caused by editor, fix

      const { data } = yield axios.get(`/api/v1/deals/${id}`);
      applySnapshot(self, { ...data, state: 'ready' });
    }),
    setName(name) {
      self.name = name; // eslint-disable-line no-param-reassign
    },
    setDescription(description) {
      self.description = description; // eslint-disable-line no-param-reassign
    },
    setContact(contact) {
      self.contact = contact; // eslint-disable-line no-param-reassign
    },
    setStartDate(date) {
      self.start = date; // eslint-disable-line no-param-reassign
    },
    setEndDate(date) {
      self.end = date; // eslint-disable-line no-param-reassign
    },
    setValue(value) {
      self.value = value; // eslint-disable-line no-param-reassign
    },
    setHourlyPrice(value) {
      self.hourly_price = value; // eslint-disable-line no-param-reassign
    },
    setCustomer(customer) {
      self.customer = customer; // eslint-disable-line no-param-reassign
    },
    setResourcingDeadline(value) {
      self.resourcing_deadline = value; // eslint-disable-line no-param-reassign
    },
    setCollapse(flag) {
      self.collapsed = flag; // eslint-disable-line no-param-reassign
    },
    setState(state) {
      self.state = state; // eslint-disable-line no-param-reassign
    },
    save: flow(function* save() {
      const snapshot = getSnapshot(self);
      self.setState('pending');

      // Mapping data for nested update TODO: try doing with post/preprocessing
      const deal = {
        ...snapshot,
        contact: undefined,
        propositions: undefined,
        requirements: undefined,
        customer: undefined,
        customer_id: snapshot.customer?.id,
        deal_propositions_attributes: self.propositions.map(proposition => ({
          ...proposition,
          id:
            proposition.id === -1 || Number.isNaN(Number(proposition.id))
              ? undefined
              : proposition.id,
          employee_id: proposition.employee?.id,
          employee: undefined
        })),
        requirements_attributes: self.requirements
      };

      if (self.id === -1) {
        const { data } = yield axios.post(
          '/api/v1/deals',
          JSON.stringify(deal)
        );
        applySnapshot(self, data);
      } else {
        const { data } = yield axios.put(
          `/api/v1/deals/${self.id}`,
          JSON.stringify(deal)
        );
        applySnapshot(self, data);
      }

      [...self.allPropositions(), ...self.allRequirements()].forEach(m => {
        if (m._destroy) destroy(m);
      });

      self.setState('ready');

      return self;
    }),
    updateResourcingDeadline: flow(function* update(deadline) {
      try {
        const { data } = yield axios.put(
          `/api/v1/deals/${self.id}`,
          // TODO: Date -1 bug strikes again, fix properly?
          JSON.stringify({
            resourcing_deadline: deadline?.format('YYYY-MM-DD') || null
          })
        );
        self.setResourcingDeadline(data.resourcing_deadline);
      } catch (err) {
        console.log('Error updating deal resourcing deadline', err); // eslint-disable-line no-console
      }
    }),
    updateCustomer: flow(function* updateCustomer(id) {
      try {
        const { data } = yield axios.put(
          `/api/v1/deals/${self.id}`,
          JSON.stringify({ customer_id: id })
        );
        self.setCustomer(data.customer);
      } catch (err) {
        console.log('Error updating deal customer id', err); // eslint-disable-line no-console
      }
    }),
    addRequirement() {
      const requirement = Requirement.create({ id: -1, amount: 1 });
      self.requirements.push(requirement);
      return requirement;
    },
    clearEmptyRequirements() {
      self.allRequirements().forEach(requirement => {
        if (requirement.isEmpty()) {
          destroy(requirement);
        }
      });
    },
    removeRequirementOrProposition(req) {
      if (req.id === -1) {
        destroy(req);
      } else {
        req.markForDestroy();
      }
    },
    createProposition(data) {
      self.propositions.push(data);
    },
    resyncFromSource: flow(function* resync() {
      self.setState('pending');
      try {
        const { data } = yield axios.get(`/api/v1/deals/${self.id}/resync`);
        applySnapshot(self, data);
      } catch (err) {
        console.log('Error resyncing deal from source', err); // eslint-disable-line no-console
      }
      self.setState('ready');
    })
  }));

export default Deal;
