import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'components/atoms';
import {
  TooltipContainer,
  HoverWindow,
  Header,
  Content
} from './tooltip-style';

const Tooltip = ({
  header,
  content,
  children,
  direction = 'bottom right',
  icon = 'guide',
  disabled = false,
  debounce = 0,
  noClick = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const tooltipRef = useRef(null);
  const debounceRef = useRef();

  const handleToggleClick = () => {
    if (!noClick) {
      setIsClicked(!isClicked);
    }
  };

  const handleMouseEnter = () => {
    if (!isClicked) {
      debounceRef.current = setTimeout(() => {
        setIsOpen(true);
      }, debounce);
    }
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      setIsOpen(false);
    }
    clearTimeout(debounceRef.current);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isOpen &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setIsClicked(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map(item =>
        typeof item === 'string' ? <Content key={item}>{item}</Content> : item
      );
    }
    return <Content>{content}</Content>;
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToggleClick}
      ref={tooltipRef}
    >
      {children || <Icon name={icon} />}
      {isOpen && !disabled && (
        <HoverWindow direction={direction}>
          {header && <Header>{header}</Header>}
          {renderContent()}
        </HoverWindow>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
