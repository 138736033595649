import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { ColorAndNameContainer, ColorCode } from './customer-search-style';

const ColorAndName = observer(({ customer, noLink }) => {
  const { id, name, color } = customer;

  const L = noLink ? 'span' : Link;

  return (
    <ColorAndNameContainer>
      <L to={`/customers/${id}`}>
        <ColorCode backgroundColor={color} />
        {name}
      </L>
    </ColorAndNameContainer>
  );
});

export default ColorAndName;
