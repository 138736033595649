import { types, flow, resolveIdentifier, getSnapshot } from 'mobx-state-tree';
import { observable } from 'mobx';
import moment from 'moment';

import axios from '../common/utils/axios';
import { Deal } from './deal-model';

const batchQueue = observable.array();

const PipelineModel = types.model('Pipeline', {
  name: types.string,
  stages: types.array(types.array(types.string))
});

export const DealList = types
  .model('DealList', {
    deals: types.map(Deal),
    pipelines: types.array(PipelineModel),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .views(self => ({
    inMonth(month, property, deals) {
      const range = moment.range(
        moment(month).startOf('month'),
        moment(month).endOf('month')
      );
      return (deals || self.all()).filter(
        deal => range.contains(deal[property] || month) // If property not found, assume we show it
      );
    },
    all() {
      return [...self.deals.values()];
    },
    byStatus(closed) {
      return self.all().filter(deal => (deal.status === 'active') ^ closed); // eslint-disable-line no-bitwise
    },
    byStatusAndMonth(closed, month) {
      const byStatus = self.byStatus(closed);

      if (!closed) return byStatus;

      const prop = closed === 1 ? 'closed_at' : 'created_at';
      return self.inMonth(month, prop, byStatus);
    },
    isLoaded() {
      return self.state === 'ready';
    },
    pipelineData() {
      return [...self.pipelines.values()];
    }
  }))
  .actions(self => ({
    createDeal(data) {
      const deal = Deal.create({ ...data, state: 'ready' });
      return self.deals.put(deal);
    },
    load: flow(function* load(params = {}) {
      if (!self.isLoaded()) {
        try {
          const { data } = yield axios.get('/api/v1/deals', { params });
          data.deals.map(deal => self.createDeal(deal));
          self.pipelines = data.pipelines; // eslint-disable-line no-param-reassign
          self.state = 'ready'; // eslint-disable-line no-param-reassign
        } catch (error) {
          console.log('Error loading deals list', error); // eslint-disable-line no-console
        }
      }
      return self;
    }),
    loadDeal(id, batch = false) {
      const deal = resolveIdentifier(Deal, self, id);
      if (deal) {
        return deal;
      }
      const d = Deal.create({ id });
      if (batch) {
        batchQueue.push(d.id);
      } else {
        d.load(id);
      }
      self.deals.put(d);
      return d;
    },
    addDeal: flow(function* addDeal(params) {
      const deal = getSnapshot(params);
      const { data } = yield axios.post(
        '/api/v1/deals',
        JSON.stringify({ deal })
      );
      self.createDeal(data);
      return data;
    }),
    deleteDeal: flow(function* deleteDeal(id) {
      self.deals.delete(id);
      yield axios.delete(`/api/v1/deals/${id}`);
    }),
    reset() {
      self.state = 'pending'; // eslint-disable-line no-param-reassign
      return self;
    }
  }));

export const sortByName = deals =>
  deals.sort((a, b) => a.name.localeCompare(b.name));

export default DealList;
