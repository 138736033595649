export const defaultFilter = () => data => data;

// Allow nested checks, such as cv.title
const deepValue = (field, model) =>
  field.split('.').reduce((o, i) => o[i], model);

const handleSpecialFilters = model => filter => {
  switch (true) {
    case filter.datePicker:
      return model[filter.field]
        ? model[filter.field][filter.comparison](filter.value, 'day')
        : false;
    case filter.field:
      return deepValue(filter.field, model) === filter.value;
    case typeof filter.value === 'function':
      return filter.value(model);
    default:
      return true;
  }
};

/*
  Returns a function based on currently active filters.

  The function is used to filter data in parent component, this way filtering
  logic can be reused, while data can be statelessly handled in parent
  component to avoid issues with mobx
*/
export const filterFunction = filters => {
  if (!filters.length) {
    return defaultFilter;
  }

  return () => data => {
    // Split filters into category and special filters, category filters have fields
    const { categoryFilters, specialFilters } = filters.reduce(
      (o, f) => {
        (f.field && !f.datePicker ? o.categoryFilters : o.specialFilters).push(
          f
        );
        return o;
      },
      { categoryFilters: [], specialFilters: [] }
    );

    const groupedCategories = categoryFilters.reduce((o, f) => {
      // eslint-disable-next-line no-param-reassign
      (o[f.category] ||= { field: f.field, values: [] }).values.push(f.value);
      return o;
    }, {});

    const filteredByCategories = data.filter(model =>
      Object.values(groupedCategories)
        .map(category =>
          category.values.flat().includes(deepValue(category.field, model))
        )
        .every(Boolean)
    );

    return filteredByCategories.filter(model =>
      specialFilters.map(handleSpecialFilters(model)).every(Boolean)
    );
  };
};

// Helpers

const addCount = (option, activeFilters, data) => {
  if (option.datePicker && !option.value) {
    return { ...option, count: null };
  }

  const count = filterFunction([...activeFilters, option])()(data).length;
  return { ...option, count };
};

const updateFilterCounts = (filters, { activeFilters, data }) => {
  if (!data) {
    return filters;
  }

  return filters.map(({ category, field, options }) => ({
    category,
    field,
    options: options.map(option =>
      addCount({ category, field, ...option }, activeFilters, data)
    )
  }));
};

const addFilter = (filters, { option, category, field, value }) => {
  return [...filters, { category, field, value, ...option }];
};

const removeFilter = (filters, option) => {
  return filters.filter(item => item.title !== option.title);
};

const updateFilter = (filters, option) => {
  return filters.map(item => (item.title === option.title ? option : item));
};

const updateDate = (filters, data) => {
  const { value, category, option } = data;
  return filters.map(filter =>
    filter.category === category
      ? {
          ...filter,
          options: filter.options.map(item =>
            item.field === option.field ? { ...option, value } : item
          )
        }
      : filter
  );
};

// Reducer to handle filter values and active filters
export const filterReducer = (state, action) => {
  const { filters, activeFilters } = state;
  switch (action.type) {
    case 'active.add':
      return {
        ...state,
        activeFilters: addFilter(activeFilters, action.payload)
      };
    case 'active.remove':
      return {
        ...state,
        activeFilters: removeFilter(activeFilters, action.payload)
      };
    case 'active.update':
      return {
        ...state,
        activeFilters: updateFilter(activeFilters, action.payload)
      };
    case 'active.reset':
      return { ...state, activeFilters: action.payload };
    case 'datepicker.set':
      return {
        ...state,
        filters: updateDate(filters, action.payload)
      };
    case 'filters.reset':
      return { ...state, filters: action.payload };
    case 'filters.count':
      return {
        ...state,
        filters: updateFilterCounts(filters, action.payload)
      };
    default:
      return state;
  }
};
