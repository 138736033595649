import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { breakpointsRefresh as breakpoints } from 'common/styles';

export const activeClassName = 'is-active';

export const Tab = styled.div`
  position: relative;
  padding: 1rem;
  border-bottom: 3px solid transparent;

  label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0.5rem;
  }
`;
export const TabLink = styled(NavLink).attrs({
  activeClassName
})`
  color: var(--color-text-light);
  text-align: center;
  white-space: nowrap;
  &:hover {
    color: var(--color-text);
    text-decoration: none;
    cursor: pointer;
    ${Tab} {
      border-color: var(--color-primary-lightest);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      input {
        pointer-events: all;
      }
    `}

  ${breakpoints.lg} {
    margin: auto;
    text-align: left;
  }

  &.${'is-active'} {
    ${Tab} {
      color: var(--color-text-darker);
      border-color: ${({ color }) => color || 'var(--color-primary)'};
    }
  }

  > button {
    opacity: 0;
    font-size: var(--font-size-sm);

    @media (pointer: coarse) {
      opacity: 1;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  font-size: var(--font-size-sm);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoints.lg} {
    position: ${({ $static }) => ($static ? 'static' : 'absolute')};
    margin-top: calc(-2.75 * var(--sideMargin) + 1px);
    z-index: 99999;
  }

  ${TabLink} + ${TabLink} {
    margin-left: 0;
    ${breakpoints.lg} {
      margin-left: .75rem;
    }
  }

  ${breakpoints.lg} {
    background-color: transparent;
    padding: 0 1rem 0;
  }

  &:hover ${TabLink} > button {
    opacity: 1;
  }
`;
