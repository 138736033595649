import styled from 'styled-components';
import { IconContainer } from '../icon/icon';

export const TooltipContainer = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  text-transform: none;

  > ${IconContainer} {
    margin-left: 0.3rem;
  }

  &:hover > span {
    color: var(--color-text-lighter);
  }

  &:hover ${IconContainer} {
    color: var(--color-text-lighter);
  }
`;

export const Header = styled.div`
  font-size: var(--font-size-md);
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--color-text);
  border-bottom: solid 1px var(--color-text-lightest);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  font-size: var(--font-size-sm);
  font-weight: 400;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const handleDirection = ({ direction }) =>
  `translate(${direction.split(' ')[1] === 'right' ? '0%' : '-100%'}, ${
    direction.split(' ')[0] === 'bottom' ? '100%' : '-100%'
  });`;

const handleHorizontalPosition = ({ direction }) =>
  direction.split(' ')[1] === 'right' ? '0' : '100%';

const handleVerticalPosition = ({ direction }) =>
  `${direction.split(' ')[0]}: 0;`;

export const HoverWindow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${handleHorizontalPosition};
  ${handleVerticalPosition}
  transform: ${handleDirection};
  border-radius: var(--border-radius-lg);
  background: var(--color-modal);
  border: 1px solid var(--color-text-lightest);
  box-shadow: var(--shadow-md);
  padding: 1rem;
  z-index: 999999 !important;
  font-size: var(--font-size-sm);
  min-width: 20rem;
  color: var(--color-text-darkest);
`;
