import React from 'react';
import { observer } from 'mobx-react';

import { Icon } from 'components/atoms';

import { SegmentWrapper, Segment } from './segment-button-style';

const SegmentButton = observer(({ segments }) => (
  <SegmentWrapper>
    {segments.map(({ icon, label, onClick, active, disabled, ...rest }) => (
      <Segment
        key={icon || label}
        onClick={onClick}
        active={active}
        disabled={disabled}
      >
        {icon ? <Icon name={icon} {...rest} /> : null}
        {label ? <span>{label}</span> : null}
      </Segment>
    ))}
  </SegmentWrapper>
));

export default SegmentButton;
