import { createGlobalStyle } from 'styled-components';

import InterRegularWoff from '../fonts/inter/inter-v2-latin-regular.woff';
import InterRegularWoff2 from '../fonts/inter/inter-v2-latin-regular.woff2';
import InterMediumWoff from '../fonts/inter/inter-v2-latin-500.woff';
import InterMediumWoff2 from '../fonts/inter/inter-v2-latin-500.woff2';
import InterSemiboldWoff from '../fonts/inter/inter-v2-latin-600.woff';
import InterSemiboldWoff2 from '../fonts/inter/inter-v2-latin-600.woff2';
import InterBoldWoff from '../fonts/inter/inter-v2-latin-700.woff';
import InterBoldWoff2 from '../fonts/inter/inter-v2-latin-700.woff2';
import InterExtraboldWoff from '../fonts/inter/inter-v2-latin-800.woff';
import InterExtraboldWoff2 from '../fonts/inter/inter-v2-latin-800.woff2';

import { breakpointsRefresh } from './';

const breakpoints = breakpointsRefresh;

export const GlobalStyle = createGlobalStyle`

:root{
   color-scheme: light;
  --color-white: #ffffff;

  --color-grey-lightest: #F1F2F2;
  --color-grey-lighter: #D4D4D6;
  --color-grey-light: #BCBCBF;
  --color-grey: #838489;
  --color-grey-dark: #555760;
  --color-grey-darker: #3F414A;
  --color-grey-darkest: #2B2C37;

  --color-black: #101017;
  --color-nega: var(--color-black);

  --color-purple-lightest: #f6f0ff;
  --color-purple-lighter: #dac7ff;
  --color-purple-light: #9a75ff;
  --color-purple: #7149f5;
  --color-purple-dark: #5334cf;
  --color-purple-darker: #3822a8;
  --color-purple-darkest: #221482;

  --color-primary-lightest: var(--color-purple-lightest);
  --color-primary-lighter: var(--color-purple-lighter);
  --color-primary-light: var(--color-purple-light);
  --color-primary: var(--color-purple);
  --color-primary-dark: var(--color-purple-dark);
  --color-primary-darker: var(--color-purple-darker);
  --color-primary-darkest: var(--color-purple-darkest);

  --color-primary-rgb: 113, 73, 245;

  --color-secondary: #110e35;

  --color-background: #fff;
  --color-background-secondary: var(--color-text-darkest);
  --color-background-panel: #FBFBFB;
  --color-modal: var(--color-background);

  --color-background-transparent: rgba(255,255,255,0);

  --color-background-hint: #FBFBFB;
  --color-background-is-active:var(--color-primary-lightest);

  --color-text-is-active:var(--color-primary);

  --color-text-lightest: var(--color-grey-lightest);
  --color-text-lightest: var(--color-grey-lightest);
  --color-text-lighter: var(--color-grey-lighter);
  --color-text-light: var(--color-grey-light);
  --color-text: var(--color-grey);
  --color-text-dark:  var(--color-grey-dark);
  --color-text-darker:  var(--color-grey-darker);
  --color-text-darkest:  var(--color-grey-darkest);

  --color-success-lightest: #E9F5E8;
  --color-success-lighter: #D0DBD1;
  --color-success-light: #B4CEB4;
  --color-success: #75B578;
  --color-success-dark: #578D5B;
  --color-success-darker: #3C6840;
  --color-success-darkest: #244129;

  --color-warning-lightest: #FFFDE8;
  --color-warning-lighter: #FCF09F;
  --color-warning-light: #FBE57E;
  --color-warning: #F3C648;
  --color-warning-dark: #CD9F38;
  --color-warning-darker: #A87A28;
  --color-warning-darkest: #835C1C;

  --color-error-lightest: #FDF3F0;
  --color-error-lighter: #F5C3B9;
  --color-error-light: #F1A190;
  --color-error: #EB5645;
  --color-error-dark: #C83E32;
  --color-error-darker: #A42A22;
  --color-error-darkest: #801C19;

  --color-input-hover: var(--color-background-hint);
  --color-input-text: var(--color-text-darkest);
  --color-input-placeholder: var(--color-text);
  --color-input-focus: var(--color-primary);

  --font-size-xxs: 0.65rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.4rem;
  --font-size-3xl: 1.6rem;
   ${breakpoints.md} {
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 1.875rem;
    }

  --font-family-primary: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  --border-radius-xs: 0.125rem;
  --border-radius-sm: 0.175rem;
  --border-radius-base: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 0.75rem;
  --border-radius-xxl: 0.85rem;
  --border-radius-full: 9999px;

  --shadow-xs: 0 1px 3px rgb(0 0 0 / 2%), 0 2px 5px rgb(0 0 0 / 2%);
  --shadow-sm: 0 1px 3px rgb(0 0 0 / 8%), 0 2px 5px rgb(0 0 0 / 8%);
  --shadow-md: 0px 4px 4px rgba(0 0 0 / 13%);
  --shadow-md-reverse: 0px -3px 3px rgba(0 0 0 / 8%);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0,0.1);
  --shadow-lg-reverse: 0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -4px rgba(0, 0, 0,0.1);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-xl-reverse: 0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 -10px 10px -5px rgba(0, 0, 0, 0.04);

  --hover-opacity-base: rgba(0, 0, 0, 0.03);
  --hover-opacity-lg: rgba(0, 0, 0, 0.06);
  --hover-opacity-dark: rgba(0, 0, 0, 0.3);

  --ease-in-expo: cubic-bezier(0.7, 0, 0.84, 0);
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);

  --sideMargin: 1.25rem;

  ${breakpoints.md} {
    --sideMargin: 2rem;
  }
}

.dark{
   color-scheme: dark;
  --color-background: #12121a;
  --color-background-hint: #202029;
  --color-background-is-active:#262636;
  --color-background-secondary: #101017;
  --color-black: #000000;
  --color-nega: #fff;
  --color-background-panel: #1B1B24;
  --color-modal: #202029;

  --color-background-transparent: rgba(18,18,26,0);

  --color-text-is-active:var(--color-text-darkest);

  --color-grey-lightest: #3A3A42;
  --color-grey-lighter: #525259;
  --color-grey-light: #717176;
  --color-grey: #99999D;
  --color-grey-dark: #C4C4C6;
  --color-grey-darker: #D7D7D8;
  --color-grey-darkest: #EEEEEF;

  --color-purple-lightest: #1E183D;
  --color-purple-lighter: #271F4F;
  --color-purple-light: #33266A;
  --color-purple: #5038B1;
  --color-purple-dark: #755EC5;
  --color-purple-darker: #9F8CDC;
  --color-purple-darkest: #CBBCEE;

  --color-success-lightest: #151F1B;
  --color-success-lighter: #274227;
  --color-success-light: #325A2F;
  --color-success: #529A45;
  --color-success-dark: #6BAD5F;
  --color-success-darker: #8CC482;
  --color-success-darkest: #B1D5AA;

  --color-warning-lightest: #292117;
  --color-warning-lighter: #554822;
  --color-warning-light: #776328;
  --color-warning: #E1C357;
  --color-warning-dark: #EEDB79;
  --color-warning-darker: #F5E99B;
  --color-warning-darkest: #F9F2C0;

  --color-error-lightest: #22141a;
  --color-error-lighter: #522525;
  --color-error-light: #732F2B;
  --color-error: #C94B3E;
  --color-error-dark: #D77062;
  --color-error-darker: #E6998A;
  --color-error-darkest: #EFBEB2;

  --shadow-xs: 0 1px 3px rgb(0 0 0 / 2%), 0 2px 5px rgb(0 0 0 / 2%);
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0 , 0.16);
  --shadow-md: 0px 4px 4px rgba(0, 0, 0, 0.26);
  --shadow-md-reverse: 0px -3px 3px rgba(0, 0, 0, 0.16);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -4px rgba(0, 0, 0, 0.2);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
  --shadow-xl-reverse: 0 -20px 25px -5px rgba(0, 0, 0, 0.2), 0 -10px 10px -5px rgba(0, 0, 0, 0.08);

  --hover-opacity-base: rgba(255, 255, 255, 0.03);
  --hover-opacity-lg: rgba(255, 255, 255, 0.06);
  --hover-opacity-dark: rgba(255, 255, 255, 0.3);
}

html {
  font-size: calc(14px + 0.1vw + 0.1vh + 0.1vmin);
  ${breakpoints.lg} {
    font-size: 16px;
    }
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}

body{
  font-family: var(--font-family-primary);
  font-weight: 500;
  line-height: 1.45;
  background-color: var(--color-background);
  color: var(--color-text);
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''), url(${InterRegularWoff2}) format('woff2'),
    url(${InterRegularWoff}) format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''), url(${InterMediumWoff2}) format('woff2'),
    url(${InterMediumWoff}) format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''), url(${InterSemiboldWoff2}) format('woff2'),
    url(${InterSemiboldWoff}) format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''), url(${InterBoldWoff2}) format('woff2'),
    url(${InterBoldWoff}) format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local(''), url(${InterExtraboldWoff2}) format('woff2'),
    url(${InterExtraboldWoff}) format('woff');
}


`;

export default GlobalStyle;
