import styled from 'styled-components/macro';
// import { breakpointsRefresh as breakpoints } from 'common/styles';

export const IncrementWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    min-width: 2.5ch;
    text-align: center;
    background: var(--color-background-hint);
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  padding: 0;
  width: 1.75rem;

  border: 1px solid var(--color-text-lightest);
  background: var(--color-background);

  cursor: pointer;
  color: var(--color-text-dark);

  &:first-child {
    border-bottom-left-radius: var(--border-radius-xxl);
    border-top-left-radius: var(--border-radius-xxl);
  }

  &:last-child {
    border-bottom-right-radius: var(--border-radius-xxl);
    border-top-right-radius: var(--border-radius-xxl);
  }

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-xs);
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    color: var(--color-text-light);
    border-color: var(--color-text-lightest);
  }

  > div {
    width: 0.8rem;
  }
`;
