import { types, getRoot, getParentOfType } from 'mobx-state-tree';

import { withValidations } from 'mst-validatejs';

import { Deal } from './deal-model';

export const Requirement = types
  .model('Requirement', {
    id: types.number,
    name: types.optional(types.string, ''),
    amount: types.number,
    tags: types.array(types.number),
    _destroy: types.maybe(types.boolean)
  })
  .extend(
    withValidations({
      name: {
        length: { minimum: 1, message: 'name is required' }
      },
      tags: {
        length: { minimum: 1, message: 'at least one tag is required' }
      },
      amount: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          message: 'at least one opening required'
        }
      }
    })
  )
  .postProcessSnapshot(snapshot => {
    if (snapshot.id === -1) {
      delete snapshot.id; // eslint-disable-line no-param-reassign
    }
    return snapshot;
  })
  .views(self => ({
    get filled() {
      const propositions = getParentOfType(self, Deal).allPropositions();
      return propositions.filter(prop => prop.role === self.name).length;
    },
    hasTag(tagId) {
      return self.allTags().includes(tagId);
    },
    allTags() {
      return [...self.tags.values()];
    },
    allTagModels() {
      const { tags } = getRoot(self);

      return self.tags.map(id => tags.get(id)).filter(Boolean);
    },
    isEmpty() {
      return self.name === '';
    },
    get color() {
      const name_hash = [...self.name].reduce(
        // eslint-disable-next-line no-bitwise
        (hash, char) => char.charCodeAt(0) + ((hash << 5) - hash),
        0
      );

      // Restrict hue to skip 40-80, they tend to result in ugly colors :<
      const h = ((Math.abs(name_hash * 164117) % 320) + 80) % 360;
      const s = 40 + Math.abs(name_hash % 60);
      const l = 30 + Math.abs(name_hash % 20);

      return `hsl(${h}, ${s}%, ${l}%)`;
    }
  }))
  .actions(self => ({
    set(property, value) {
      self[property] = value; // eslint-disable-line no-param-reassign
    },
    setName(name) {
      self.set('name', name);
    },
    setAmount(amount) {
      self.set('amount', amount || 1);
    },
    loadTags() {
      return self; // We're using IDs, no need to load
    },
    toggleTag(tagId) {
      if (self.hasTag(tagId)) {
        self.tags.replace(self.tags.filter(id => id !== tagId));
      } else {
        self.tags.push(tagId);
        self.initializeName(tagId);
      }

      // Tag list expects a promise :/
      return new Promise(resolve => resolve(self));
    },
    initializeName(tagId) {
      try {
        const { tags } = getRoot(self);
        const { name, category } = tags.get(tagId);

        const requirements = getParentOfType(self, Deal).allRequirements();

        const hasSameName = requirements.some(req => req.name === name);

        if (category === 'role' && self.name === '' && !hasSameName) {
          self.setName(name);
        }
      } catch {} // eslint-disable-line no-empty
    },
    markForDestroy() {
      self._destroy = true; // eslint-disable-line no-param-reassign
    }
  }));

export default Requirement;
