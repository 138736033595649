import React from 'react';
import Permission from './permission';
import { rbac } from './rules';

const DealsReadPermission = props => (
  <Permission perform={rbac.DEALS_READ} {...props}>
    {props.children}
  </Permission>
);

export default DealsReadPermission;
