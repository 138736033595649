import styled, { css } from 'styled-components/macro';
// import { breakpointsRefresh as breakpoints } from 'common/styles';

export const SegmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 0.5rem 0;
`;

export const Segment = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  padding: 0.25rem 0.75rem;

  border: 1px solid var(--color-text-lightest);
  background: transparent;

  cursor: pointer;
  color: var(--color-text);

  white-space: nowrap;

  ${({ active }) =>
    active &&
    css`
      background: var(--color-background-is-active);
      color: var(--color-text-dark);
    `};

  &:first-child {
    border-bottom-left-radius: var(--border-radius-xxl);
    border-top-left-radius: var(--border-radius-xxl);

    margin-right: -1px;
    padding-left: 1rem;
  }

  &:last-child {
    border-bottom-right-radius: var(--border-radius-xxl);
    border-top-right-radius: var(--border-radius-xxl);

    margin-left: -1px;
    padding-right: 1rem;
  }

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-xs);
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    color: var(--color-text-lighter);
    border-color: var(--color-text-lightest);
    transform: unset;
    box-shadow: none;
  }

  > span {
    margin-left: 0.25rem;
  }
`;
